<template>
  <div class="wysiwyg">
    <div class="flex lg:flex-wrap flex-wrap-reverse pb-10">
      <div class="w-full lg:w-1/2 px-4">
        <div class="text-center lg:mt-0 mt-8">
          <img
            class="inline-block w-[170px] lg:w-[220px]"
            src="@/assets/role.png"
            alt=""
          />
        </div>
      </div>
      <div class="w-full lg:w-1/2 px-4">
        <div class="lg:mt-12 border border-gray-500 p-6 rounded-lg">
          <span class="block mb-4">
            遇到困難了嗎?<br />
            酷獸小幫手來幫助你 !
          </span>
          <div>
            <a href="#visit" class="block text-xl text-gray-800 font-bold"
              >參觀須知</a
            >
            <a
              v-if="$route.name === 'Editor.Preview'"
              href="#preview"
              class="block mt-1 text-xl text-gray-800 font-bold"
              >預覽展間</a
            >
          </div>
        </div>
      </div>
    </div>
    <h2 class="" id="visit">參觀須知</h2>
    <h3>動線與步驟</h3>
    <ol class="list-decimal ml-5">
      <li>
        關於本雲端博物館線上展覽參觀動線，主要基於展場光線與展示設計等因素，目前設定為最加參觀路徑。
      </li>
      <li>
        參觀者於進入展場後，可點選地面所示「觀賞點」欣賞展品，或是依工具欄之導覽地圖確認所在位置，並依個人喜好選擇參觀模式或自由點選展品欣賞。
      </li>
      <li>點擊展品，可直接看見該展品資訊、介紹及詳細圖片。</li>
      <li>
        欣賞展品詳細圖片時，可自由放大縮小觀看，3D展品更可自由旋轉與平移觀看。
      </li>
    </ol>
    <h3>工具指示</h3>

    <table class="table border-collapse table-auto w-full text-sm">
      <thead>
        <th class="w-16">項次</th>
        <th class="text-left">說明</th>
        <th class="w-16">圖示</th>
      </thead>
      <tbody>
        <tr v-for="(item, i) in icons" :key="item.id">
          <td class="text-center">{{ i + 1 }}</td>
          <td>{{ item.text }}</td>
          <td><img class="w-8 black" :src="`${item.svg}`" alt="" /></td>
        </tr>
      </tbody>
    </table>
    <template v-if="$route.name === 'Editor.Preview'">
      <!-- <h2 id="preview">預覽展間</h2>
      <h3>空間</h3>
      <ol class="list-decimal ml-5">
        <li>進入展場</li>
        <li>地圖→上一頁/參觀順序/下一頁</li>
        <li>關閉預覽</li> -->
      <!-- </ol> -->
      <h3>展品欣賞</h3>
      <ol class="list-decimal ml-5">
        <li>作品資訊</li>
        <li>資訊順序：作品名→作者名→年代→類別→尺寸→登錄號→展品說明</li>
        <li>語音導覽</li>
        <li>操作工具提示(可放大或縮小觀看作品)</li>
      </ol>
    </template>
  </div>
</template>
<script>
import { reactive } from 'vue';
export default {
  setup() {
    const icons = reactive([
      {
        text: '點擊圓圈圖示，可前往參觀。',
        svg: require('@/assets/exhibitclick.svg'),
        id: 'audio',
      },
      {
        text: '按著滑鼠左鍵拖曳，可上下左右旋轉鏡頭欣賞；按著滑鼠右鍵可平移觀看；使用滑鼠滾輪可以縮放畫面觀看。',
        id: 'exhibitdrag',
        svg: require('@/assets/exhibitdrag.svg'),
      },
      {
        text: '音樂',
        id: 'audio',
        svg: require('@/assets/audio.svg'),
      },
      {
        text: '地圖',
        id: 'floorplan',
        svg: require('@/assets/floorplan.svg'),
      },
      { text: '分享', id: 'share', svg: require('@/assets/share.svg') },
      { text: '進入展場', id: 'enter', svg: require('@/assets/enter.svg') },
      { text: '參觀須知', id: 'howto', svg: require('@/assets/howto.svg') },
      {
        text: '放大觀看',
        id: 'zoom-in',
        svg: require('@/assets/zoom-in.svg'),
      },
      {
        text: '縮小觀看',
        id: 'zoom-out',
        svg: require('@/assets/zoom-out.svg'),
      },
    ]);
    return { icons };
  },
};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
